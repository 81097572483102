import styles from './ClipDetail.module.scss'
import {toAbsoluteUrl} from 'helpers'
import {useContext} from 'react'
// Context
import {AppLevelContext} from 'context/AppLevelContext'
const ClipDetail = ({clip}: any) => {
  const {user} = useContext(AppLevelContext)
  //   FORMAT TIMESTAMP TO DATE
  const getTime = (timestamp: any) => {
    const date = new Date(timestamp)
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const hoursFormatted = hours < 10 ? `0${hours}` : hours
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes
    return `${hoursFormatted}:${minutesFormatted} `
  }

  const getDate = (timestamp: any) => {
    const date = new Date(timestamp)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const dayString = day < 10 ? `0${day}` : day
    const monthString = month < 10 ? `0${month}` : month
    return `${dayString}-${monthString}-${year}`
  }

  return (
    <div className={styles.clipDetail}>
      <div className={styles.clipDetail_title}>
        <span className={styles.brandName}>
          <img src={clip.client_logo} alt='brand' />
          {clip.client_name}
        </span>
        <span className={styles.adName}>{clip.campaign_name}</span>
      </div>
      <div className={styles.clipDetail_info}>
        <div className={styles.clipViews}>
          <img src={toAbsoluteUrl('/assets/icons/MyClips/viewsIcon.png')} alt='views' />
          {clip?.views?.toLocaleString()}
        </div>
        <div className={styles.clipRevenue}>
          <img src={toAbsoluteUrl('/assets/icons/MyClips/revenueIcon.png')} alt='revenue' />
          {clip.revenue?.toFixed(2).toLocaleString()} {user?.currency_sign}
        </div>
      </div>
      <div className={styles.clipDetail_date}>
        {getDate(clip.timestamp)} - {getTime(clip.timestamp)}
      </div>
    </div>
  )
}

export default ClipDetail
