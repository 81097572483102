import styles from './MyClipsPopup.module.scss'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import {useState, useContext, useEffect} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import ClickAwayListener from 'react-click-away-listener'
import axios from 'axios'
import {toast} from 'react-toastify'
import {useTranslation} from 'react-i18next'

//COMPONENTSz
import ClipDetail from './ClipDetail/ClipDetail'
import Labels from './Labels/Labels'
import Problems from './Problems/Problems'
// Context
import {AppLevelContext} from 'context/AppLevelContext'
import Loading from 'components/Shared/Loading/Loading'
import { createClipService } from 'services/clipService'

const MyClipsPopup = () => {
  const clipService = createClipService()
  const location = useLocation()
  const adId = location.pathname.split('/')[3]
  const navigate = useNavigate()
  const [submitTab, setSubmitTab] = useState('labels')
  const [clip, setClip] = useState<any>({})
  const [labels, setLabels] = useState<any>([])
  const [problems, setProblems] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const {t} = useTranslation()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  // GET SINGLE CLIP
  const getClip = async () => {
    setIsLoading(true)
    try {
      // const response = await axios.get(
      //   `${process.env.REACT_APP_STREAMER_API_URL}/clips/${adId}`,
      //   config
      // )
      const response = await clipService.getClip(parseInt(adId))
      setClip(response)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (adId) {
      getClip()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adId])

  // LABEL SELECTION
  useEffect(() => {
    if (clip.labels) {
      setLabels(clip.labels)
    }
  }, [clip.labels])

  // PROBLEM SELECTION
  useEffect(() => {
    if (clip.problems) {
      setProblems(clip.problems)
    }
  }, [clip.problems])

  // PUT LABELS
  const putLabels = async () => {
    try {
      const body: any = {}
      const Reqbody: any = []
      labels.forEach((label: any) => {
        if (label.selected === 1) {
          Reqbody.push(label.id)
        }
      })

      if (Reqbody.length === 0) {
        toast.error('Please select at least one label')
        return
      }
      body.labels = Reqbody
      await clipService.updateLabels(parseInt(adId), body.labels)
      toast.success('Labels Updated!')
    } catch (error) {
      console.log(error)
    }
  }

  // PUT PROBLEMS
  const putProblems = async () => {
    try {
      const body: any = {}
      const Reqbody: any = []
      problems.forEach((problem: any) => {
        if (problem.selected === 1) {
          Reqbody.push(problem.id)
        }
      })
      if (Reqbody.length === 0) {
        toast.error('Please select at least one problem')
        return
      }
      body.problems = Reqbody
      // await axios.put(
      //   `${process.env.REACT_APP_STREAMER_API_URL}/clips/${adId}/problems`,
      //   body,
      //   config
      // )
      await clipService.updateProblems(parseInt(adId), body.problems)
      toast.success('Problems Updated!')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={styles.outterContainer}>
      <ClickAwayListener
        onClickAway={() => {
          navigate('/dashboard')
        }}
      >
        <div className={styles.myClips_container}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <button
                className={styles.closeBtn}
                onClick={() => {
                  navigate('/dashboard')
                }}
              >
                <img src={toAbsoluteUrl('/assets/icons/closeIcon.png')} alt='close' />
              </button>
              <div className={styles.myClips_left}>
                <ClipDetail clip={clip} />
                <video autoPlay controls muted>
                  <source src={clip.clip_link} type='video/mp4' />
                  Your browser does not support the video.
                </video>
              </div>
              <div className={styles.myClips_right}>
                <div className={styles.myClips_information}>
                  <img
                    src={toAbsoluteUrl('/assets/icons/MyClips/informationIcon.png')}
                    alt='info'
                  />
                  {submitTab === 'labels'
                    ? t('MyClipsPopup.clipSubmissionExplanation')
                    : t('MyClipsPopup.adDisplayOrChatLinkProblem')}
                </div>

                {submitTab === 'labels' ? (
                  <Labels labels={labels} setLabels={setLabels} />
                ) : (
                  <Problems problems={problems} setProblems={setProblems} />
                )}

                <div className={styles.submitButtons}>
                  <button
                    className={styles.tabSwitchBtn}
                    onClick={() => {
                      if (submitTab === 'labels') {
                        setSubmitTab('problems')
                      } else {
                        setSubmitTab('labels')
                      }
                    }}
                  >
                    {submitTab === 'labels' ? (
                      <>
                        <img
                          src={toAbsoluteUrl('/assets/icons/MyClips/problemIcon.png')}
                          alt='problem'
                        />
                        {t('MyClipsPopup.reportProblem')}
                      </>
                    ) : (
                      <>
                        <img
                          src={toAbsoluteUrl('/assets/icons/MyClips/problemIcon.png')}
                          alt='problem'
                        />
                        {t('MyClipsPopup.labels')}
                      </>
                    )}
                  </button>
                  <button
                    className={classNames(
                      styles.submitBtn,
                      submitTab === 'problems' && styles.problemsBtn
                    )}
                    onClick={(e) => {
                      e.preventDefault()
                      if (submitTab === 'labels') {
                        putLabels()
                      } else {
                        putProblems()
                      }
                    }}
                  >
                    {t('Buttons.submit')}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default MyClipsPopup
