import styles from './MyClips.module.scss'
import classNames from 'classnames'
import {useLocation, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from 'helpers'
import {useState, useContext, useEffect} from 'react'
import {AppLevelContext} from 'context/AppLevelContext'
import axios from 'axios'
import MyClipsPopup from './MyClipsPopup/MyClipsPopup'
import ClickAwayListener from 'react-click-away-listener'
import {useTranslation} from 'react-i18next'
import { createClipService } from 'services/clipService'

const MyClips = () => {
  const clipService = createClipService()
  const location = useLocation()
  const navigate = useNavigate()
  const [myClips, setMyClips] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [filter, setFilter] = useState('all')
  const {t} = useTranslation()

  // GET ALL CLIPS
  const getAllClips = async (filterName: string) => {
    setIsLoading(true)
    const query = () => {
      if (filterName === 'all') {
        return ''
      } else if (filterName === 'bad_language') {
        return '?is_against_brand_safety=1'
      } else if (filterName === 'brilliant') {
        return '?is_great_promo=1'
      } else if (filterName === 'clickbait') {
        return '?is_clickbait=1'
      } else {
        return ''
      }
    }
    try {
      const response = await clipService.getClips(query())
      setMyClips(response.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getTime = (timestamp: any) => {
    const date = new Date(timestamp)
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const hoursFormatted = hours < 10 ? `0${hours}` : hours
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes
    return `${hoursFormatted}:${minutesFormatted} `
  }

  const getDate = (timestamp: any) => {
    const date = new Date(timestamp)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const dayString = day < 10 ? `0${day}` : day
    const monthString = month < 10 ? `0${month}` : month
    return `${dayString}-${monthString}-${year}`
  }

  useEffect(() => {
    getAllClips(filter)
    // eslint-disable-next-line
  }, [filter])
  return (
    <>
      <div className={styles.myClips}>
        <div className={styles.myClips_header}>
          <div className={styles.myClips_title}>
            <img src={toAbsoluteUrl('/assets/icons/Dashboard/myClipsIcon.png')} alt='icon' />
            {t('DashboardPage.myClips')}
          </div>
          <div className={styles.myClips_desc}>{t('DashboardPage.clipsAutomatedLivestream')}</div>
        </div>
        <div className={styles.myClips_categories}>
          <button
            className={classNames(styles.myClips_categoriesItem, {
              [styles.active]: filter === 'all',
            })}
            onClick={() => setFilter('all')}
          >
            {t('DashboardPage.all')}
          </button>
          <button
            className={classNames(styles.myClips_categoriesItem, {
              [styles.active]: filter === 'brilliant',
            })}
            onClick={() => setFilter('brilliant')}
          >
            {t('DashboardPage.brilliant')}
          </button>
          <button
            className={classNames(styles.myClips_categoriesItem, {
              [styles.active]: filter === 'bad_language',
            })}
            onClick={() => setFilter('bad_language')}
          >
            {t('DashboardPage.dangerous')}
          </button>
          <button
            className={classNames(styles.myClips_categoriesItem, {
              [styles.active]: filter === 'clickbait',
            })}
            onClick={() => setFilter('clickbait')}
          >
            {t('DashboardPage.clickbait')}
          </button>
        </div>

        <div className={styles.myClips_content}>
          {myClips.map((item: any, index: number) => (
            <div
              className={styles.myClips_contentItem}
              key={index}
              onClick={() => navigate(`/dashboard/my-clips-popup/${item.ad_id}`)}
            >
              <video className={styles.myClips_contentItem_video}>
                <source src={item.clip_link} type='video/mp4' />
              </video>
              <img
                src={toAbsoluteUrl('/assets/icons/Dashboard/clipPlayIcon.png')}
                alt='play'
                className={styles.playIcon}
              />
              <div className={styles.myClips_contentItem_info}>
                <div className={styles.myClips_contentItem_title}>
                  <img src={item.client_logo} alt='brand' />
                  {item.client_name}
                </div>
                <div className={styles.myClips_contentItem_date}>
                  {getDate(item.timestamp)} {getTime(item.timestamp)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {location.pathname.includes('/my-clips-popup') && <MyClipsPopup />}
    </>
  )
}

export default MyClips
