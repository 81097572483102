import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
import {toAbsoluteUrl} from 'helpers'
import styles from './LiveConsole.module.scss'
import {useState, useContext, useEffect} from 'react'
import {loadingHandler} from 'helpers'

// Context
import LiveNotificationContext from 'context/LiveNotificationContext'
import LiveConsoleContext from 'context/LiveConsoleContext'

//COMPONENTS
import Campaigns from './Components/Campaigns/Campaigns'
import Games from './Components/Games/Games'
import Switch from './Components/Switch/Switch'
import Toggle from './Components/Toggle/Toggle'
import Range from './Components/Range/Range'
import LiveNotifications from './Components/LiveNotifications/LiveNotifications'
import Loading from 'components/Shared/Loading/Loading'
import {AppLevelContext} from 'context/AppLevelContext'
import EnumRole from 'services/model/enum/enumRole'
// import AdComing from './Components/AdComing/AdComing'

const LiveConsole = () => {
  const {getAllDonations} = useContext(LiveNotificationContext)
  const {getAllCampaigns, activeFilter, getAdSettings, adSettings, setAdSettings, saveAdSettings} =
    useContext(LiveConsoleContext)
  const {user} = useContext(AppLevelContext)
  const [activeTab, setActiveTab] = useState('campaigns')
  const [pageLoading, setPageLoading] = useState(true)
  const [campaignLoading, setCampaignLoading] = useState(true)
  const {t} = useTranslation()
  document.title = t('Pages.liveConsole') + ' • LIVAD'

  // Initial load
  useEffect(() => {
    if (user?.role === EnumRole.MOD && !user?.self) {
      return
    }
    loadingHandler([getAllCampaigns(), getAdSettings()], setPageLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Watch for campaign filter changes
  useEffect(() => {
    if (user?.role === EnumRole.MOD && !user?.self) {
      return
    }
    loadingHandler([getAllCampaigns()], setCampaignLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter])

  return (
    <div className={styles.liveConsole_container}>
      <div className={styles.containerRow}>
        <LiveNotifications />
      </div>
      {
        !(user?.role === EnumRole.MOD && !user.self) ? (
          <>
          {pageLoading ? (
            <Loading />
          ) : (
            <div className={styles.containerRow}>
              <div className={styles.leftSide}>
                <div className={styles.tabSelection}>
                  <div
                    className={classNames(styles.tab, activeTab === 'campaigns' && styles.active)}
                    onClick={() => {
                      setActiveTab('campaigns')
                    }}
                  >
                    {t('LiveConsolePage.campaigns')}
                  </div>
                  <div
                    className={classNames(styles.tab, activeTab === 'games' && styles.active)}
                    onClick={() => {
                      setActiveTab('games')
                    }}
                  >
                    {t('LiveConsolePage.games')}
                    <span className={styles.betaLabel}>{t('LiveConsolePage.beta')}</span>
                  </div>
                </div>
                {campaignLoading ? <Loading /> : activeTab === 'campaigns' ? <Campaigns /> : <Games />}
              </div>
    
              {/* Ads Manager */}
              <div className={styles.rightSide}>
                <div className={styles.sideItem}>
                  <div className={styles.sideTitle}>
                    <img
                      src={toAbsoluteUrl('/assets/icons/LiveConsole/adsManagerIcon.png')}
                      alt='adsManager'
                    />
                    {t('LiveConsolePage.adsManager')}
                  </div>
                </div>
                <div className={styles.sideItem}>
                  <div className={styles.sideItemTitle}>{t('LiveConsolePage.adsService')}</div>
                  <div className={styles.sideItemContent}>{t('LiveConsolePage.pauseAdsText')}</div>
                  <Switch />
                </div>
                <div className={styles.sideItem}>
                  <div className={styles.sideItemTitle}>{t('LiveConsolePage.placementMode')}</div>
                  <div className={styles.sideItemContent}>{t('LiveConsolePage.adsAppearChoice')}</div>
                  <Toggle />
                </div>
                <div className={styles.sideItem}>
                  <div className={styles.sideItemTitle}>{t('LiveConsolePage.adFrequency')}</div>
                  <div className={styles.sideItemContent}>
                    {t('LiveConsolePage.displayAdsFrequency')}
                  </div>
                  <div className={styles.sideItemWarning}>
                    <img
                      src={toAbsoluteUrl('/assets/icons/LiveConsole/warningIcon.svg')}
                      alt='warning'
                    />
                    {t('LiveConsolePage.validPeakCampaign')}
                  </div>
                  <Range type={'frequency'} />
                </div>
                <div className={styles.sideItem}>
                  <div className={styles.sideItemTitle}>{t('LiveConsolePage.startDelay')}</div>
                  <div className={styles.sideItemContent}>
                    {t('LiveConsolePage.startDisplayAdsDelay')}
                  </div>
                  <Range type={'delay'} />
                </div>
              </div>
            </div>
          )}
          </>
        ): null
      }
    </div>
  )
}

export default LiveConsole
