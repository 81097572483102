import {useContext, useState} from 'react'
import classNames from 'classnames'

//STYLES
import styles from './SponsorshipPreferences.module.scss'

//COMPONENTS
import SearchBrand from './Components/SearchBrand/SearchBrand'
import CustomToggle from 'components/CustomToggle/CustomToggle'
import SettingsContext from 'context/SettingsContext'
import EnumStreamerAutoAcceptPreference from 'services/model/enum/enumStreamerAutoAcceptPreference'
import Loading from 'components/Shared/Loading/Loading'
import SelectInput from 'components/Shared/SelectInput/SelectInput'
import {createStreamersService} from 'services/streamersService'
import {useLocation, useNavigate} from 'react-router-dom'
import EnumSettingsTab from 'services/model/enum/enumSettingsTab'
import {useTranslation} from 'react-i18next'
import checkEmptyNumber from 'helpers/checkEmptyNumber'
import {AppLevelContext} from 'context/AppLevelContext'

const SponsorshipPreferences = () => {
  const {
    autoAcceptSettings,
    setAutoAcceptSettings,
    getAutoAcceptSettingsDiff,
    checkAutoAcceptSettingsSave,
    updateAutoAcceptSettings,
  } = useContext(SettingsContext)
  const {user} = useContext(AppLevelContext)
  const location = useLocation()
  const routePath = location.pathname.split('/')[1]
  const navigate = useNavigate()
  const {t} = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  if (!autoAcceptSettings || !user) {
    return <Loading />
  }
  return (
    <div className={styles.sponsorshipPreferences}>
      <div className={styles.sectionTitle}>{t('SettingsPage.sponsorshipPreferences')}</div>
      <div className={styles.line}>
        <div className={styles.label}>{t('OnboardingPage.setupSponsorshipPreferences')}</div>
        <div className={styles.inLine}>
          <div className={styles.radioList}>
            <div
              className={classNames(styles.radio, {
                [styles.active]:
                  autoAcceptSettings?.autoAcceptPreference === EnumStreamerAutoAcceptPreference.ALL,
              })}
              onClick={() => {
                // setSponsorshipPreferences('auto')
                setAutoAcceptSettings({
                  ...autoAcceptSettings,
                  autoAcceptPreference: EnumStreamerAutoAcceptPreference.ALL,
                })
              }}
            >
              <div className={styles.radioInner}>
                <div className={styles.radioInnerInner}></div>
              </div>
              <div className={styles.radioText}>
                {t('SettingsPage.autoAcceptAllOffers')}
                <div className={styles.recommended}>{t('SettingsPage.recommended')}</div>
              </div>
            </div>
            <div
              className={classNames(styles.radio, {
                [styles.active]:
                  autoAcceptSettings?.autoAcceptPreference ===
                  EnumStreamerAutoAcceptPreference.BRAND,
              })}
              onClick={() => {
                // setSponsorshipPreferences('specific')
                setAutoAcceptSettings({
                  ...autoAcceptSettings,
                  autoAcceptPreference: EnumStreamerAutoAcceptPreference.BRAND,
                })
              }}
            >
              <div className={styles.radioInner}>
                <div className={styles.radioInnerInner}></div>
              </div>
              <div className={styles.radioText}>{t('SettingsPage.autoAcceptAllOffersBrands')}</div>
            </div>
            <div
              className={classNames(styles.radio, {
                [styles.active]:
                  autoAcceptSettings?.autoAcceptPreference ===
                  EnumStreamerAutoAcceptPreference.MANUAL,
              })}
              onClick={() => {
                // setSponsorshipPreferences('manual')
                setAutoAcceptSettings({
                  ...autoAcceptSettings,
                  autoAcceptPreference: EnumStreamerAutoAcceptPreference.MANUAL,
                })
              }}
            >
              <div className={styles.radioInner}>
                <div className={styles.radioInnerInner}></div>
              </div>
              <div className={styles.radioText}>{t('SettingsPage.manuallyAcceptOffers')}</div>
            </div>
          </div>
        </div>
      </div>

      {/* {sponsorshipPreferences !== 'manual' && ( */}
      {autoAcceptSettings?.autoAcceptPreference !== EnumStreamerAutoAcceptPreference.MANUAL && (
        <>
          <div className={styles.line}>
            <div className={styles.label}>
              {t('SettingsPage.minSponsorshipOffer')}
              <CustomToggle
                isToggleEnable={autoAcceptSettings.autoAcceptMinimum !== null}
                setIsToggleEnable={() => {
                  setAutoAcceptSettings({
                    ...autoAcceptSettings,
                    autoAcceptMinimum: autoAcceptSettings.autoAcceptMinimum !== null ? null : 1,
                  })
                }}
              />
            </div>
            {autoAcceptSettings.autoAcceptMinimum !== null && (
              <>
                <div className={styles.desc}>{t('SettingsPage.minSponsorshipOfferAmount')}</div>
                <div className={styles.inLine}>
                  <span className={styles.currencyIcon}>{user?.currency_sign}</span>
                  <input
                    value={autoAcceptSettings.autoAcceptMinimum ?? ''}
                    type='number'
                    placeholder={t('SettingsPage.amount') ?? undefined}
                    // parse integer
                    onChange={(e) => {
                      setAutoAcceptSettings({
                        ...autoAcceptSettings,
                        autoAcceptMinimum: parseInt(e.target.value),
                      })
                    }}
                    onKeyDown={(e) => {
                      const {key} = e
                      if (checkEmptyNumber(parseInt(key)) && key !== 'Backspace') {
                        e.preventDefault()
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {/* {sponsorshipPreferences === 'specific' && ( */}
          {autoAcceptSettings?.autoAcceptPreference === EnumStreamerAutoAcceptPreference.BRAND && (
            <div className={styles.line}>
              <div className={styles.label}>{t('SettingsPage.selectBrandsSponsorshipOffer')}</div>
              <div className={styles.desc}>{t('SettingsPage.chooseBrandSponsorshipOffer')}</div>
              <div className={styles.inLine}>
                <SearchBrand />
              </div>
            </div>
          )}
        </>
      )}
      {routePath === 'signup' ? (
        <div className={classNames(styles.line, styles.lineSave)}>
          <button
            onClick={async () => {
              navigate(`/${routePath}/${EnumSettingsTab.StreamingPreferences}`)
            }}
          >
            {t('Buttons.back')}
          </button>
          <button
            disabled={!checkAutoAcceptSettingsSave() || isLoading}
            onClick={async () => {
              setIsLoading(true)
              const success = await updateAutoAcceptSettings()
              setIsLoading(false)
              if (success) {
                navigate(`/${routePath}/${EnumSettingsTab.Interests}`)
              }
            }}
            className={classNames({
              [styles.disabled]: !checkAutoAcceptSettingsSave(),
            })}
          >
            {t('Buttons.next')}
          </button>
        </div>
      ) : (
        <>
          {checkAutoAcceptSettingsSave() && getAutoAcceptSettingsDiff() ? (
            <div className={classNames(styles.line, styles.lineSave)}>
              <button
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true)
                  await updateAutoAcceptSettings()
                  setIsLoading(false)
                }}
              >
                {t('Buttons.save')}
              </button>
            </div>
          ) : null}
        </>
      )}
    </div>
  )
}

export default SponsorshipPreferences
