import {onIdTokenChanged, signInWithCustomToken, signOut, getAuth} from 'firebase/auth'
import {createContext, useEffect, useState} from 'react'
import {auth} from '../firebase/firebaseConfig'
import {toast} from 'react-toastify'
import {LoginResponse} from 'services/model/response/loginResponse'
import {createStreamersService} from 'services/streamersService'
import i18next from 'i18next'
import {createAdminService} from 'services/adminService'
import {GetManagedStreamersResponse} from 'services/model/response/admin/getManagedStreamerResponse'
import {ManagedStreamer} from 'services/model/dto/managedStreamer'
import NotificationDto from 'services/model/dto/notifications/notificationDto'

interface AppLevelContextInterface {
  user: LoginResponse | null
  getUser: () => Promise<void>
  handleLogout: () => void
  permissions: string[]
  userSearchList: GetManagedStreamersResponse | null
  searchStreamers: (search: string) => void
  updateStreamer: (streamerId: string) => void
  setUserSearchList: (value: GetManagedStreamersResponse | null) => void
  mainStreamer: ManagedStreamer | null
  notifications: NotificationDto[] | null
  unreadNotifications: number | null
  getNotifications: () => void
  readNotifications: (notificationIds: number[]) => Promise<void>
  userLoading: boolean
  initialLoading: boolean
  tokenLoading: boolean
}

export const AppLevelContext = createContext<AppLevelContextInterface>({
  user: null,
  getUser: async () => {},
  handleLogout: () => {},
  permissions: [],
  userSearchList: null,
  searchStreamers: () => {},
  updateStreamer: () => {},
  setUserSearchList: () => {},
  mainStreamer: null,
  notifications: null,
  unreadNotifications: null,
  getNotifications: () => {},
  readNotifications: async () => {},
  userLoading: true,
  initialLoading: true,
  tokenLoading: true,
})

interface AppLevelContextProviderProps {
  children: React.ReactNode
}

export const AppLevelContextProvider = ({children}: AppLevelContextProviderProps) => {
  const streamerService = createStreamersService()
  const adminService = createAdminService()
  const [token, setToken] = useState<string | null>(null)
  const [user, setUser] = useState<LoginResponse | null>(null)
  const [permissions, setPermissions] = useState<string[]>([])
  const [userSearchList, setUserSearchList] = useState<GetManagedStreamersResponse | null>(null)
  const [mainStreamer, setMainStreamer] = useState<ManagedStreamer | null>(null)
  const [notifications, setNotifications] = useState<NotificationDto[] | null>(null)
  const [unreadNotifications, setUnreadNotifications] = useState<number | null>(null)
  const [userLoading, setUserLoading] = useState<boolean>(true)
  const [initialLoading, setInitialLoading] = useState<boolean>(true)
  const [tokenLoading, setTokenLoading] = useState<boolean>(true)

  const getUser = async () => {
    setUserLoading(true)
    try {
      const user = await streamerService.login()
      setUser(user)
      i18next.changeLanguage(user.language_preference)
    } catch (error) {
      console.log(error)
    } finally {
      setUserLoading(false)
    }
  }

  const handleLogout = async () => {
    try {
      await signOut(auth)
      setToken(null)
      setUser(null)
    } catch (error) {
      console.log(error)
    }
  }

  const searchStreamers = async (search: string) => {
    const response = await adminService.searchStreamers(search)
    setUserSearchList(response)
    if (response.self) {
      setMainStreamer(response.self)
    }
  }

  const updateStreamer = async (streamerId: string) => {
    try {
      const response = await adminService.updateAdminStreamer(streamerId)
      console.log('success', response)
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  const getNotifications = async () => {
    try {
      const response = await streamerService.getNotifications()
      setNotifications(response)
    } catch (error) {
      console.log(error)
    }
  }

  const readNotifications = async (ids: Array<number>) => {
    // Get ids from indexes
    try {
      if (!notifications) {
        return
      }
      await streamerService.readNotifications(ids)
      // Select the notifications that were read and mark them as isRead:true
      const newNotifications = notifications.map((notification, index) => {
        if (ids.includes(notification.id)) {
          return {
            ...notification,
            isRead: true,
          }
        }
        return notification
      })
      setNotifications(newNotifications)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (token) {
      getUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    setInitialLoading(true)
    const urlParams = new URLSearchParams(window.location.search)
    const customToken = urlParams.get('customToken')
    const error = urlParams.get('error')
    console.log({
      customToken,
      error,
      urlParams,
    })
    if (customToken) {
      signInWithCustomToken(auth, customToken)
        .then((userCredential) => {
          setInitialLoading(false)
        })
        .catch((error) => {
          console.error('Error signing in with custom token:', error)
        })
    } else {
      setInitialLoading(false)
    }
    if (error) {
      // console.log({error})
      toast.error(error)
      handleLogout()
    }
  }, [])

  // const checkLoggedIn = () => {
  //   const user = auth.currentUser;
  //   if (!user) {
  //     setUserLoading(false)
  //     return
  //   }
  // }

  useEffect(() => {
    // checkLoggedIn()
    if (initialLoading) return
    const unsubscribe = onIdTokenChanged(auth, async (user) => {
      console.log('user', user)
      setTokenLoading(false)
      if (user) {
        const idTokenResult = await user.getIdTokenResult(true)
        const authToken = idTokenResult.token
        setToken(authToken)
      } else {
        setToken(null)
        setUserLoading(false)
      }
    })
    return () => unsubscribe()
  }, [initialLoading])

  useEffect(() => {
    if (notifications) {
      // Get the count of notifications items that has isRead: false
      const unreadNotifications = notifications.filter((notification) => !notification.isRead)
      setUnreadNotifications(unreadNotifications.length)
    } else {
      setUnreadNotifications(null)
    }
  }, [notifications])

  return (
    <AppLevelContext.Provider
      value={{
        user,
        getUser,
        handleLogout,
        permissions,
        userSearchList,
        searchStreamers,
        updateStreamer,
        setUserSearchList,
        mainStreamer,
        notifications,
        unreadNotifications,
        getNotifications,
        readNotifications,
        userLoading,
        initialLoading,
        tokenLoading,
      }}
    >
      {children}
    </AppLevelContext.Provider>
  )
}
