import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl, calculateRemainingTime} from 'helpers'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'
// TYPES
import {Campaign} from 'context/CampaignContext'
// STYLES
import styles from '../../CardView.module.scss'
// CONTEXT
import {AppLevelContext} from 'context/AppLevelContext'

interface SingleCardItemProps {
  campaign: Campaign
  variant?: 'featured' | ''
}

const SingleCardItem = ({campaign, variant = ''}: SingleCardItemProps) => {
  const {t} = useTranslation()
  const {user} = useContext(AppLevelContext)
  const navigate = useNavigate()
  const {days, text} = calculateRemainingTime(campaign.end_date)

  return (
    <div className={styles.card_item}>
      <div className={styles.item_header}>
        <span
          className={classNames(styles.remaining_time, {
            [styles.past_campaign]: campaign.campaign_status === 'past',
          })}
        >
          <img
            src={
              campaign.campaign_status !== 'past'
                ? toAbsoluteUrl('/assets/icons/Campaigns/remaining-time-icon.png')
                : toAbsoluteUrl('/assets/icons/Campaigns/finished-campaign-icon.png')
            }
            alt=''
          />
          {campaign.campaign_status === 'past'
            ? t('CampaignsPage.past')
            : days === 0
            ? t('CampaignsPage.lastDay')
            : t('CampaignsPage.xDaysLeft', {X: days})}
        </span>
        <img
          src={campaign.url_to_logo}
          alt=''
          className={styles.client_logo}
          onError={(e) => {
            e.currentTarget.src = toAbsoluteUrl(
              '/assets/icons/Dashboard/Brands/defaultBrandAvatar.png'
            )
          }}
        />
      </div>
      <div className={styles.item_content}>
        <span className={styles.client_name}>{campaign.client_name}</span>
        <span className={styles.campaign_name}>{campaign.campaign_name}</span>
        <div className={styles.campaign_sub}>
          <span className={styles.campaign_type}>
            <img
              src={
                ['poll', 'interactive'].includes(campaign.campaign_type)
                  ? toAbsoluteUrl('/assets/icons/Campaigns/poll-icon.png')
                  : toAbsoluteUrl('/assets/icons/Campaigns/video-icon.png')
              }
              alt=''
            />

            {t(campaign.campaign_type.slice(0, 1).toUpperCase() + campaign.campaign_type.slice(1))}
          </span>
          {variant !== 'featured' && (
            <span className={styles.campaign_rpm}>
              <b>RPM:</b>
              {campaign.CPM} {user?.currency_sign}
            </span>
          )}
        </div>
        <button
          className={styles.campaign_inspect}
          onClick={() => navigate(`/campaigns/campaign-popup/${campaign.campaign_id}`)}
        >
          <img src={toAbsoluteUrl('/assets/icons/Campaigns/inspect-icon.png')} alt='' />
          {t('CampaignsPage.inspect')}
        </button>
      </div>
    </div>
  )
}

export default SingleCardItem
